import { Fragment, useCallback, useEffect, useState } from 'react'

import {
  Dialog,
  Disclosure,
  Transition,
  TransitionChild,
} from '@headlessui/react'
import {
  ArrowTopRightOnSquareIcon,
  Bars3CenterLeftIcon,
  BoltIcon,
  BookmarkIcon,
  BookOpenIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  CircleStackIcon,
  CogIcon,
  EnvelopeIcon,
  FolderIcon,
  HomeIcon,
  HomeModernIcon,
  LifebuoyIcon,
  LightBulbIcon,
  MapIcon,
  RocketLaunchIcon,
  Squares2X2Icon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import { BeakerIcon, CogIcon as SolidCogIcon } from '@heroicons/react/24/solid'
import { Popper, Tooltip } from '@mui/material'
import { captureException } from '@sentry/browser'
import { FLAG_FEATURES, PROD_CLIENT } from 'api/src/common/enums'
import { isProduction } from 'api/src/common/environment'
import {
  getQuickLinks,
  getQuickLinksVariables,
  UpdateUserSidebarMutation,
  UpdateUserSidebarMutationVariables,
} from 'types/graphql'
import { useLocalStorage, useMediaQuery } from 'usehooks-ts'

import { Link, navigate, routes } from '@redwoodjs/router'
import { useMutation, useQuery } from '@redwoodjs/web'

// @ts-expect-error: It's a RW thing
import AliasSwitcherCell from 'src/components/AliasSwitcherCell/AliasSwitcherCell'
import StatuspageLinkCell from 'src/components/StatuspageLinkCell'
import useLiveFeature from 'src/lib/hooks/LiveFeatureFlag/UseLiveFeature'
import useAnalytics from 'src/lib/hooks/useAnalytics'
import TheHubIcon from 'src/lib/icons/TheHubIcon/TheHubIcon'
import { HubLogomark, TheHubLogo } from 'web/src/lib/svg/SvgComponents'
import { useAuth } from 'web/src/Providers'

import IconButton from '../IconButton/IconButton'
import Button from '../Library/Button/Button'
import ReloadModal from '../Sidebar/ReloadModal'

import { getInsightsMenu, getLearnMenu, getPeopleMenu } from './DynamicItems'
import { SidebarIcon } from './SidebarIcon'
import SidebarLink, { NavigationItem } from './SidebarLink'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

type InformerSectionsType = getQuickLinks['informerSections']

const QUICK_LINKS_AND_SA_PERMISSION = gql`
  query getQuickLinks($isPinnedToSidebar: Boolean!) {
    informerSections: informerSectionsByClientAndPinnedStatusAndUserLevelAccess(
      isPinnedToSidebar: $isPinnedToSidebar
    ) {
      id
      name
      isPinnedToSidebar
      iconStorageObject {
        id
        downloadUrl
      }
      membershipGroups {
        id
        membershipGroupId
      }
      informerListCards {
        id
        name
        url
        isEmbed
        membershipGroups {
          id
          membershipGroupId
        }
      }
    }
  }
`
const UPDATE_USER = gql`
  mutation UpdateUserSidebarMutation($input: UpdateUserInput!, $id: Int!) {
    updateUser(input: $input, id: $id) {
      activeMembershipId
    }
  }
`
const chatId = process.env.FRONT_THE_HUB_CHAT_ID

const Sidebar = ({ children }) => {
  const { currentUser, hasRole } = useAuth()

  const { trackEvent } = useAnalytics()

  // LIVE FEATURE TOGGLES
  const [propertyGenieLive] = useLiveFeature(FLAG_FEATURES.PROPERTY_GENIE)
  const [orgChartLive] = useLiveFeature(FLAG_FEATURES.ORG_CHART)
  const [customBaserowFormulaLive] = useLiveFeature(
    FLAG_FEATURES.CUSTOM_BASEROW_FORMULA,
  )
  const [sentimentAnalysisLive] = useLiveFeature(
    FLAG_FEATURES.SENTIMENT_ANALYSIS,
  )
  const [clientFeatureSettingsLive] = useLiveFeature(
    FLAG_FEATURES.CUSTOMER_INTEGRATIONS,
  )
  const [knowledgeBaseLive] = useLiveFeature(FLAG_FEATURES.KNOWLEDGE_BASE)
  const [npsLive] = useLiveFeature(FLAG_FEATURES.NPS_INSIGHTS)
  const [learnerCoursesLive] = useLiveFeature(FLAG_FEATURES.LEARNER_COURSES)
  const [courseReportingLive] = useLiveFeature(FLAG_FEATURES.COURSE_REPORTING)
  const [goalsLive] = useLiveFeature(FLAG_FEATURES.GOALS)
  const [informerDirectoryLive] = useLiveFeature(
    FLAG_FEATURES.INFORMER_DIRECTORY,
  )
  const [reactMapsLive] = useLiveFeature(FLAG_FEATURES.REACT_MAPS)
  const [revenueProfilesLive] = useLiveFeature(FLAG_FEATURES.REVENUE_PROFILES)
  const [automationLive] = useLiveFeature(FLAG_FEATURES.AUTOMATIONS)
  const [hubDashLive] = useLiveFeature(FLAG_FEATURES.HUBDASH)
  const [basesLive] = useLiveFeature(FLAG_FEATURES.BASES)
  const [importerLive] = useLiveFeature(FLAG_FEATURES.HUBS_IMPORTERS)
  const [isAuditLogLive] = useLiveFeature(FLAG_FEATURES.AUDIT_LOGS)

  const isMobile = useMediaQuery('(max-width: 640px)')

  const [openChat, setOpenChat] = useState(false)
  const currentMembershipId = currentUser?.membershipData?.id
  const [staleMembership, setStaleMembership] = useState(false)
  const [staleAlias, setStaleAlias] = useState(false)
  const isSupportMode = currentUser?.isClientAlias
  const clientId =
    currentUser?.userData?.clientAlias ??
    currentUser?.userData?.activeMembership?.client?.id
  const [clientAlias, setClientAlias] = useLocalStorage<number>(
    'clientAlias',
    currentUser?.userData?.clientAlias,
  )
  const [activeMembershipId, setActiveMembershipId] = useLocalStorage<number>(
    'activeMembershipid',
    currentMembershipId,
  )
  // Need to set these initial values on load because useLocalStorage won't write them if they already exist
  useEffect(() => {
    setClientAlias(currentUser?.userData?.clientAlias)
    setActiveMembershipId(currentMembershipId)
  }, [])

  // This function will check if the membership or clientAlias in the currentUser object are stale
  const checkMembershipAndClientAlias = useCallback(() => {
    if (
      currentMembershipId !== activeMembershipId &&
      currentMembershipId !== -1
    ) {
      setStaleMembership(true)
    } else setStaleMembership(false)
    if (clientAlias !== currentUser?.userData?.clientAlias) {
      setStaleAlias(true)
    } else setStaleAlias(false)
  }, [
    activeMembershipId,
    clientAlias,
    currentMembershipId,
    currentUser?.userData?.clientAlias,
  ])

  useEffect(() => {
    window.addEventListener('focus', checkMembershipAndClientAlias)
    checkMembershipAndClientAlias()
    return () => {
      // Cleanup:
      window.removeEventListener('focus', checkMembershipAndClientAlias)
    }
  }, [checkMembershipAndClientAlias])

  useEffect(() => {
    checkMembershipAndClientAlias()
  }, [currentMembershipId, checkMembershipAndClientAlias])

  useEffect(() => {
    if (chatId && openChat && window.Intercom) {
      window.Intercom('show')
      window.Intercom('onHide', function () {
        setOpenChat(false)
      })
    } else {
      window.Intercom('hide')
    }
    if (isMobile) {
      window.intercomSettings = {
        hide_default_launcher: true,
      }
    }
  }, [currentUser, openChat, isMobile])

  const activeUserMembershipGroupIds =
    currentUser?.membershipData?.membershipGroups.map(
      (membership) => membership?.id,
    )

  const [pinnedSections, setPinnedSections] = useState<InformerSectionsType>([])

  useQuery<getQuickLinks, getQuickLinksVariables>(
    QUICK_LINKS_AND_SA_PERMISSION,
    {
      variables: { isPinnedToSidebar: true },
      onCompleted: (data) => {
        if (data.informerSections?.length > 0) {
          setPinnedSections(data.informerSections)
        }
      },
      onError(error) {
        captureException(error)
      },
    },
  )

  if (!currentUser?.userData?.userAgreementAccepted) {
    navigate(routes.endUserTermsAndConditionsPrompt())
  }

  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [isAliasSwitcherOpen, setAliasSwitcherOpen] = useState(false)

  const STORAGE_KEY = 'myToggleState'
  const [toggle, setToggle] = useState(() => {
    const storedValue = localStorage.getItem(STORAGE_KEY)
    return storedValue !== null ? JSON.parse(storedValue) : false
  })

  const handleSideBarToggle = () => {
    setToggle((prevState: boolean) => {
      const newState = !prevState
      trackEvent('Uncategorized', 'Sidebar toggle', {
        expand: prevState,
      })

      localStorage.setItem(STORAGE_KEY, JSON.stringify(newState))
      return newState
    })
  }

  const [updateUserMutation] = useMutation<
    UpdateUserSidebarMutation,
    UpdateUserSidebarMutationVariables
  >(UPDATE_USER, {
    onCompleted: () => {
      navigate(routes.home())
      location.reload()
    },
    onError: (error) => {
      // eslint-disable-next-line no-console
      console.error(error)
    },
  })

  const closeAliasSwitcher = () => {
    setAliasSwitcherOpen(false)
  }

  const selectAlias = (clientId: number) => {
    if (clientId === 1) {
      // eslint-disable-next-line no-console
      console.error('Alias to Stafflink is not supported')
      return
    }

    updateUserMutation({
      variables: {
        id: currentUser.userData.id,
        input: {
          clientAlias: clientId,
        },
      },
    })
    setClientAlias(clientId)
  }

  const navigation: NavigationItem[] = [
    {
      id: 'home-menu',
      name: 'Home',
      href: routes.homeApp(),
      icon: HomeIcon,
    },
    getPeopleMenu({
      isMobile,
      informerDirectoryLive,
      orgChartLive,
    }),
    !isMobile &&
      hubDashLive && {
        id: 'hubDash-menu',
        name: 'HubDash',
        href: routes.hubDash(),
        icon: Squares2X2Icon,
      },
    !isMobile &&
      basesLive && {
        name: 'Bases',
        id: 'bases-menu',
        href: routes.bases(),
        icon: CircleStackIcon,
      },
    getLearnMenu({
      isMobile,
      learnerCoursesLive,
      courseReportingLive,
      isEditorOrAbove: hasRole(['ADMIN', 'OWNER', 'EDITOR']),
    }),
    reactMapsLive && {
      id: 'react-maps-menu',
      name: 'Process Maps',
      href: routes.reactMap(),
      icon: MapIcon,
    },
    knowledgeBaseLive && {
      id: 'knowledge-base-menu',
      name: 'Knowledge Base',
      href: routes.knowledge(),
      icon: BookOpenIcon,
    },
    goalsLive && {
      id: 'goals-menu',
      name: 'Goals',
      href: routes.improverGoals(),
      icon: LightBulbIcon,
    },
    !isMobile &&
      propertyGenieLive && {
        id: 'property-genie-menu',
        name: 'Ad Generator',
        href: routes.propertyGenie(),
        icon: HomeModernIcon,
      },
    !isMobile &&
      !isSupportMode &&
      sentimentAnalysisLive && {
        id: 'sentiment-analysis-menu',
        name: 'Sentiment Analysis',
        href: routes.sentimentAnalysis(),
        icon: EnvelopeIcon,
      },
    !isMobile &&
      hasRole(['ADMIN', 'OWNER', 'SUPERADMIN']) &&
      automationLive && {
        id: 'automation-menu',
        name: 'Automations',
        href: routes.automations(),
        icon: BoltIcon,
      },
    isMobile &&
      hasRole(['ADMIN', 'OWNER', 'SUPERADMIN']) && {
        id: 'admin-menu',
        name: 'Admin Settings',
        href: '#',
        icon: CogIcon,
        current: false,
        children: [
          {
            id: 'announcements-settings',
            name: 'Announcements',
            href: routes.settingsAnnouncements(),
          },
          {
            id: 'group-management-settings',
            name: 'Groups Management',
            href: routes.settingsGroups(),
          },
        ],
      },
    getInsightsMenu({
      isMobile,
      npsLive,
      revenueProfilesLive,
    }),
  ].filter(Boolean)

  if (pinnedSections.length > 0 && !isMobile) {
    // Filter pinned items by user membership access
    const filteredPinnedMenuOptionsByMembershipAccess = pinnedSections.filter(
      (menuOption) => {
        // IF no membership groups, the menu item is universal
        if (!menuOption?.membershipGroups.length) return true

        // RETURN true/false if activeUserMemberships is assigned to the menu item
        return menuOption?.membershipGroups.some((membershipGroup) => {
          return activeUserMembershipGroupIds.includes(
            membershipGroup.membershipGroupId,
          )
        })
      },
    )

    for (const section of filteredPinnedMenuOptionsByMembershipAccess) {
      const sectionLinksFilteredByUserAccess = section.informerListCards.filter(
        (card) => {
          if (card?.membershipGroups.length > 0) {
            // Only show the link if assigned to group
            return card?.membershipGroups.some((membershipGroup) => {
              return activeUserMembershipGroupIds.includes(
                membershipGroup?.membershipGroupId,
              )
            })
          } else {
            // Show the link. No group assigned means it is global
            return true
          }
        },
      )

      if (sectionLinksFilteredByUserAccess.length === 1) {
        // Only one card in list - set as single main menu item
        const singleCard = section?.informerListCards[0]

        const menuItem = {
          id:
            (singleCard &&
              section &&
              `quick-link-${singleCard?.id}-${section?.id}`) ||
            '',
          cardId: singleCard?.id,
          sectionId: section?.id,
          name: singleCard?.name ?? '',
          href: singleCard?.url ?? '',
          isEmbed: singleCard?.isEmbed ?? false,
          icon: singleCard?.isEmbed ? BookmarkIcon : ArrowTopRightOnSquareIcon,
          customIcon: section.iconStorageObject && (
            <SidebarIcon section={section} />
          ),
          target: '_blank',
          rel: 'noreferrer',
          children: null,
        }
        // Push single cards as nav item
        navigation.push(menuItem)
      } else if (sectionLinksFilteredByUserAccess.length > 1) {
        // Multiple cards in list - set main menu dropdown items
        const menuItemChildren = sectionLinksFilteredByUserAccess.map(
          (card) => {
            return {
              id:
                (card && section && `quick-link-${card?.id}-${section?.id}`) ||
                '',
              cardId: card?.id,
              sectionId: section?.id,
              name: card?.name ?? '',
              href: card?.url ?? '#',
              isEmbed: card?.isEmbed ?? false,
              target: '_blank',
              rel: 'noreferrer',
            }
          },
        )

        // Create dropdown menu item
        const menuItem = {
          id: section?.name.toLowerCase().replace(' ', '-') ?? '',
          path: '/app/embed/' + section?.id,
          name: section?.name ?? '',
          href: '#',
          icon: FolderIcon,
          customIcon: section.iconStorageObject && (
            <SidebarIcon section={section} />
          ),
          children: menuItemChildren ?? null,
        }

        // Push multiple cards as dropdown items
        navigation.push(menuItem)
      }
    }
  } else if (pinnedSections.length > 0 && isMobile) {
    // Filter pinned items by user membership access
    const filteredPinnedMenuOptionsByMembershipAccess = pinnedSections.filter(
      (menuOption) => {
        // IF no membership groups, the menu item is universal
        if (!menuOption?.membershipGroups.length) return true

        // RETURN true/false if activeUserMemberships is assigned to the menu item
        return menuOption?.membershipGroups.some((membershipGroup) => {
          return activeUserMembershipGroupIds.includes(
            membershipGroup.membershipGroupId,
          )
        })
      },
    )

    for (const section of filteredPinnedMenuOptionsByMembershipAccess) {
      const sectionLinksFilteredByUserAccess = section.informerListCards.filter(
        (card) => {
          if (card?.membershipGroups.length > 0) {
            // Only show the link if assigned to group
            return card?.membershipGroups.some((membershipGroup) => {
              return activeUserMembershipGroupIds.includes(
                membershipGroup?.membershipGroupId,
              )
            })
          } else {
            // Show the link. No group assigned means it is global
            return true
          }
        },
      )

      for (const card of sectionLinksFilteredByUserAccess) {
        const menuItem = {
          id: card?.name.toLowerCase().replace(' ', '-') ?? '',
          cardId: card?.id,
          sectionId: section?.id,
          name: card?.name ?? '',
          href: card?.url ?? '',
          isEmbed: card?.isEmbed ?? false,
          target: '_blank',
          rel: 'noreferrer',
          icon: card?.isEmbed ? BookmarkIcon : ArrowTopRightOnSquareIcon,
          customIcon: section.iconStorageObject && (
            <SidebarIcon section={section} />
          ),
          children: null,
        }
        // Push single cards as nav item
        navigation.push(menuItem)
      }
    }
  }

  if (hasRole(['ADMIN', 'OWNER', 'EDITOR']) && !isMobile) {
    const adminNavigation = {
      name: 'Administration',
      id: 'administration-menu',
      path: '/app/settings/',
      href: '#',
      icon: CogIcon,
      children: [],
    }

    if (hasRole(['ADMIN', 'OWNER']) && !isMobile) {
      adminNavigation.children.push({
        id: 'member-management-administration-menu',
        name: 'Member Management',
        href: routes.settingsUsers(),
      })
      adminNavigation.children.push({
        id: 'client-settings-administration-menu',
        name: 'Client Settings',
        href: routes.settingsClientConfiguration(),
      })

      if (isAuditLogLive) {
        adminNavigation.children.push({
          id: 'audit-log-administration-menu',
          name: 'Audit Log',
          href: routes.auditLogs(),
        })
      }

      if (clientFeatureSettingsLive) {
        adminNavigation.children.push({
          id: 'customer-integrations-administration-menu',
          name: 'Integrations',
          href: routes.customerIntegrations(),
        })
      }

      if (sentimentAnalysisLive) {
        adminNavigation.children.push({
          id: 'sentiment-analysis-administration-menu',
          name: 'Sentiment Analysis',
          href: routes.settingsSentimentAnalysis(),
        })
      }

      if (customBaserowFormulaLive) {
        adminNavigation.children.push({
          id: 'baserow-formulas',
          name: 'Baserow Formula Generator',
          href: routes.formulaBuilder(),
          beta: true,
        })
      }
    }

    if (!isMobile) {
      adminNavigation.children.push({
        id: 'announcements-menu',
        name: 'Announcements',
        href: routes.settingsAnnouncements(),
      })

      adminNavigation.children.push({
        id: 'home-links-menu',
        name: 'Home Links',
        href: routes.settingsClientTools(),
      })

      if (npsLive) {
        adminNavigation.children.push({
          id: 'nps-administration-menu',
          name: 'NPS',
          href: routes.settingsNps(),
        })
      }
      if (learnerCoursesLive) {
        adminNavigation.children.push({
          id: 'learner-administration-menu',
          name: 'Learner',
          href: routes.settingsLearnerCategories(),
        })
      }
      if (importerLive && hasRole(['STAFFLINK', 'SUPERADMIN'])) {
        adminNavigation.children.push({
          id: 'importers-menu',
          name: 'Importers',
          href: routes.hubsImporters(),
        })
      }
    }

    navigation.push(adminNavigation)
  }

  if (hasRole(['SUPERADMIN']) && !isMobile) {
    // Global super admin options
    const superNavigationOptions = [
      {
        id: 'client-management-clients',
        name: 'Client Management',
        href: routes.clients(),
      },
      {
        id: 'feature-dashboard',
        name: 'Feature Dashboard',
        href: routes.featureDashboard(),
      },
      {
        id: 'client-settings-integrations',
        name: 'Integrations',
        href: routes.integrations(),
      },
      {
        id: 'settings-asset-library',
        name: 'Asset Library',
        href: routes.assetLibrary(),
      },
      {
        id: 'client-settings-home-links',
        name: 'Default Home Links',
        href: routes.defaultHomeLinks(),
      },
      {
        id: 'queuer-dashboard',
        name: 'Queuer Dashboard',
        href: routes.queuerDashboard(),
      },
      {
        id: 'baserow-importer',
        name: 'Baserow Importer',
        href: routes.baserowImport(),
      },
      {
        id: 'baserow-tools',
        name: 'Baserow Tools',
        href: routes.baserowTools(),
      },
      {
        id: 'webhooks',
        name: 'Webhooks',
        href: routes.webhooks(),
      },
    ]

    const superAdminNavigation = {
      id: 'super-administration-menu',
      name: 'Super Admin',
      path: '/superadmin/',
      href: '#',
      icon: SolidCogIcon,
    }

    if (clientId === PROD_CLIENT.STAFFLINK) {
      navigation.push({
        ...superAdminNavigation,
        children: superNavigationOptions.filter(Boolean),
      })
    }
  }

  const lowerNavigation: NavigationItem[] = []

  const openSubMenu = (menuItemPath: string) => {
    return location.pathname.includes(menuItemPath)
  }

  // State variables for managing individual popover state
  const [popoverOpen, setPopoverOpen] = useState({})
  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null)

  // Event handler for mouse enter
  const handlePopoverOpen = (event, identifier) => {
    let id
    if (typeof identifier === 'string') {
      id = identifier
    } else if (typeof identifier === 'object') {
      id = identifier.name
    } else {
      return // Handle invalid input, or remove this line if you prefer no action
    }

    setPopoverOpen((prevState) => ({
      ...prevState,
      [id]: true,
    }))
    setPopoverAnchorEl(event.currentTarget)
  }

  // Event handler for mouse leave
  const handlePopoverClose = (identifier) => {
    let id
    if (typeof identifier === 'string') {
      id = identifier
    } else if (typeof identifier === 'object') {
      id = identifier.name
    } else {
      return // Handle invalid input, or remove this line if you prefer no action
    }

    setPopoverOpen((prevState) => ({
      ...prevState,
      [id]: false,
    }))
  }

  return (
    <>
      <div className="min-h-full">
        <Transition show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 z-40 flex bg-black/70 lg:hidden"
            onClose={() => setSidebarOpen(false)}
          >
            <TransitionChild
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <div className="relative flex w-full max-w-xs flex-1 flex-col bg-gray-100 pb-4 pt-5">
                <TransitionChild
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute right-0 top-0 -mr-12 pt-2">
                    <Button
                      fullWidth={false}
                      variant="text"
                      className="min-w-[0px] !bg-transparent"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </Button>
                  </div>
                </TransitionChild>

                <div className="w-full items-center px-4">
                  {!isProduction && (
                    <div className="mb-2 w-full p-2">
                      <div className="w-full rounded-lg bg-red-500 p-1 text-center">
                        <p className="text-xl font-bold text-white">
                          {process.env.ENVIRONMENT}
                        </p>
                      </div>
                    </div>
                  )}
                  {currentUser?.isClientAlias && (
                    <div className="mb-2 w-full p-2">
                      <Link to="#" onClick={() => setAliasSwitcherOpen(true)}>
                        <div className="w-full rounded-lg bg-blue-500 p-1 text-center transition-all hover:bg-blue-400">
                          <p className="text-xl font-bold text-white">
                            Support Mode
                          </p>
                        </div>
                      </Link>
                    </div>
                  )}
                  <div className="flex items-center justify-center pb-2">
                    <TheHubIcon size="xsmall" />
                  </div>
                </div>
                {(staleMembership || staleAlias) && (
                  <div className="mb-2 w-full p-2">
                    <div className="w-full rounded-lg bg-red-500 p-1 text-center">
                      <p className="text-xl font-bold text-white">
                        Membership Switched !
                      </p>
                    </div>
                  </div>
                )}
                <div className="mt-2 h-0 flex-1 overflow-y-auto sm:mt-5">
                  <nav className="px-2">
                    <div className="space-y-1" data-item="sidebar-navigation">
                      {navigation.map((navItem) =>
                        !navItem.children ? (
                          <Disclosure as="div" key={navItem.name}>
                            <SidebarLink
                              navItem={navItem}
                              className="group flex w-full items-center rounded-md bg-gray-100 py-2 pl-2 text-sm font-medium text-gray-900"
                              setSidebarOpen={() => setSidebarOpen(false)}
                            >
                              {navItem.customIcon ? (
                                navItem.customIcon
                              ) : (
                                <navItem.icon
                                  className="mr-3 h-6 w-6 flex-shrink-0 text-gray-500"
                                  aria-hidden="true"
                                />
                              )}
                              {navItem.name}
                            </SidebarLink>
                          </Disclosure>
                        ) : (
                          <Disclosure
                            as="div"
                            key={navItem.name}
                            className="space-y-1"
                            defaultOpen={openSubMenu(navItem.path)}
                          >
                            <Disclosure.Button className="group flex w-full items-center rounded-md bg-white py-2 pl-2 pr-1 text-left text-sm font-medium text-gray-900 focus:outline-none focus:ring-2 focus:ring-indigo-500">
                              {navItem.customIcon ? (
                                navItem.customIcon
                              ) : (
                                <navItem.icon
                                  className="mr-3 h-6 w-6 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                                  aria-hidden="true"
                                />
                              )}
                              <span className="flex-1">{navItem.name}</span>
                              <svg
                                className="ml-3 h-5 w-5 flex-shrink-0 transform text-gray-300 transition-colors duration-150 ease-in-out group-hover:text-gray-400"
                                viewBox="0 0 20 20"
                                aria-hidden="true"
                              >
                                <path
                                  d="M6 6L14 10L6 14V6Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </Disclosure.Button>
                            <Disclosure.Panel className="py-2 pl-2 pr-1 text-sm font-medium text-gray-600">
                              {navItem.children
                                .filter(Boolean)
                                .map((subItem) => (
                                  <SidebarLink
                                    key={subItem.name}
                                    navItem={subItem}
                                    setSidebarOpen={() => setSidebarOpen(false)}
                                    className="group flex w-full items-center rounded-md py-2 pl-2 pr-1 text-sm font-medium text-gray-600 hover:bg-gray-50 hover:text-gray-900"
                                  >
                                    {subItem.name}
                                  </SidebarLink>
                                ))}
                            </Disclosure.Panel>
                          </Disclosure>
                        ),
                      )}
                      <Disclosure>
                        <SidebarLink
                          navItem={{
                            id: 'support-menu',
                            name: 'Help & Support',
                            href: '',
                            icon: LifebuoyIcon,
                          }}
                          className="group flex w-full items-center rounded-md bg-gray-100 py-2 pl-2 text-sm font-medium text-gray-900"
                          setIntercomOpen={() => setOpenChat(!openChat)}
                        >
                          <LifebuoyIcon className="mr-3 h-6 w-6 flex-shrink-0 text-gray-500" />
                          Help & Support
                        </SidebarLink>
                      </Disclosure>
                    </div>
                  </nav>
                  <div className="mt-4 flex flex-col items-start justify-center gap-y-3 px-4 text-xs text-gray-400">
                    <Link
                      to={routes.privacyPolicy()}
                      className="hover:underline"
                    >
                      Privacy Policy
                    </Link>
                    <Link
                      to={routes.acceptableUsePolicy()}
                      className="hover:underline"
                    >
                      Acceptable Use Policy
                    </Link>
                    <Link to={routes.endUserTermsAndConditions()} className="">
                      End User Terms & Conditions
                    </Link>
                  </div>
                </div>
              </div>
            </TransitionChild>
            <div className="w-14 flex-shrink-0" aria-hidden="true">
              {/* Dummy element to force sidebar to shrink to fit close icon */}
            </div>
          </Dialog>
        </Transition>
        <div className="flex">
          {/* Static sidebar for desktop */}
          <div
            className={classNames(
              toggle ? '' : 'sidebar-desktop-open',
              'sidebar-desktop relative hidden items-center border-r border-gray-200 lg:inset-y-0 lg:flex lg:h-screen lg:w-16 lg:flex-col lg:border-r lg:bg-white lg:pb-4 lg:pt-2',
            )}
          >
            {!isProduction && (
              <div className="w-full px-2 pb-2">
                <div className="w-full rounded bg-red-500 p-1 text-center uppercase">
                  <p
                    className={`text-white ${
                      toggle ? 'text-[10px]' : 'text-xs uppercase'
                    }`}
                  >
                    {process.env.ENVIRONMENT}
                  </p>
                </div>
              </div>
            )}
            {(staleMembership || staleAlias) && <ReloadModal />}
            <div
              className={classNames(
                toggle
                  ? 'flex w-full flex-col items-center px-2'
                  : 'flex w-full items-center justify-center pl-2',
              )}
            >
              <Button
                variant="text"
                className="flex min-w-[0px] items-center py-1 pl-1 pr-0"
                onClick={() => {
                  navigate(routes.home())
                }}
              >
                <div
                  className={`flex justify-center rounded-md bg-white ${
                    toggle ? 'h-8 w-8' : 'h-10 w-10'
                  } overflow-hidden ring-1 ring-gray-100 ring-opacity-80`}
                >
                  {currentUser?.parentData?.logomarkUrl ? (
                    <img
                      alt="logo"
                      role="presentation"
                      src={currentUser?.parentData?.logomarkUrl}
                      className="h-full w-full cursor-pointer object-contain hover:opacity-80"
                    />
                  ) : (
                    <img
                      alt="fallback logo"
                      src="/StafflinkLogomark.png"
                      className="h-full w-full cursor-pointer object-contain hover:opacity-80"
                    />
                  )}
                </div>
                {!toggle && (
                  <span className="ml-1 flex min-w-0 flex-1 flex-col px-1">
                    <span className="truncate text-left text-xs font-medium text-gray-700">
                      {currentUser?.parentData.name}
                    </span>
                    <span className="text-left text-xs text-gray-500">
                      {currentUser?.userData.name}
                    </span>
                  </span>
                )}
              </Button>
              {!toggle ? (
                <Tooltip title={'Collapse Sidebar'} placement="right" arrow>
                  <div>
                    <IconButton
                      onClick={handleSideBarToggle}
                      aria-label="Toggle sidebar"
                      className="!m-0 rounded-md !bg-white !px-0 py-6 hover:!bg-gray-50"
                    >
                      <ChevronLeftIcon className={'h-4 w-4 text-gray-400'} />
                    </IconButton>
                  </div>
                </Tooltip>
              ) : (
                <Tooltip title={'Expand Sidebar'} placement="right" arrow>
                  <div>
                    <IconButton
                      className="mt-2 rounded-md bg-gray-50 p-4 hover:!bg-gray-100"
                      onClick={handleSideBarToggle}
                      aria-label="Toggle sidebar"
                    >
                      <ChevronRightIcon className={'h-6 w-6 text-gray-400'} />
                    </IconButton>
                  </div>
                </Tooltip>
              )}
            </div>
            {currentUser.isClientAlias && (
              <div className="w-full p-2">
                <Link to="#" onClick={() => selectAlias(null)}>
                  <div className="w-full rounded bg-red-500 p-1 text-center transition-all hover:bg-red-400">
                    <p
                      className={`text-white ${
                        toggle ? 'text-[10px]' : 'text-xs uppercase'
                      }`}
                      data-testid="support-mode-banner"
                    >
                      Exit Support Mode
                    </p>
                  </div>
                </Link>
              </div>
            )}
            {/* Sidebar component, swap this element with another sidebar if you like */}
            <div className="mt-2 flex h-full w-full flex-1 flex-col overflow-y-auto overflow-x-hidden">
              <div className="flex h-full w-full flex-col items-center justify-between">
                <nav className="sidebar-top w-full border-t border-gray-200 pt-2">
                  <div className="flex w-full flex-col items-center justify-start pt-0.5">
                    {navigation.map((navItem) =>
                      !navItem.children ? (
                        <div key={navItem.id} className="w-full">
                          {/* Top level with no children */}
                          <SidebarLink
                            navItem={navItem}
                            className="sidebar-item-default"
                          >
                            {navItem.customIcon ? (
                              navItem.customIcon
                            ) : (
                              <navItem.icon
                                className="sidebar-item-icon"
                                aria-hidden="true"
                              />
                            )}
                            <div className="sidebar-expanded-link !bg-transparent">
                              {navItem.name}
                            </div>
                          </SidebarLink>
                        </div>
                      ) : (
                        <div
                          className="relative min-h-[38px] w-full"
                          key={navItem.id}
                        >
                          <div
                            className="w-full"
                            onMouseEnter={(e) => handlePopoverOpen(e, navItem)}
                            onMouseLeave={() => handlePopoverClose(navItem)}
                          >
                            <SidebarLink
                              navItem={navItem}
                              className={classNames(
                                'sidebar-item-default relative',
                                openSubMenu(navItem.path)
                                  ? 'sidebar-item-current'
                                  : '',
                              )}
                            >
                              {navItem.customIcon ? (
                                navItem.customIcon
                              ) : (
                                <navItem.icon
                                  className="sidebar-item-icon"
                                  aria-hidden="true"
                                />
                              )}
                              <div className="sidebar-expanded-link">
                                {navItem.name}
                              </div>
                              <svg
                                className={classNames(
                                  toggle ? '-right-0.5' : 'right-2',
                                  'absolute top-1 h-3 w-3 flex-shrink-0',
                                )}
                                viewBox="0 0 20 20"
                                aria-hidden="true"
                              >
                                <path
                                  d="M6 6L14 10L6 14V6Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </SidebarLink>

                            {popoverOpen[navItem.name] && (
                              <Popper
                                className="sidebar-popper"
                                open={popoverOpen[navItem.name]}
                                anchorEl={popoverAnchorEl}
                                placement="right-start"
                                modifiers={[
                                  {
                                    name: 'offset',
                                    options: {
                                      offset: [0, 0],
                                    },
                                  },
                                ]}
                              >
                                {({ TransitionProps }) => (
                                  <div
                                    {...TransitionProps}
                                    className="z-50 w-[250px] rounded-md bg-white p-3 shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none"
                                  >
                                    {/* Popover content */}
                                    <span className="py-3 text-xs font-bold uppercase text-gray-500">
                                      {navItem.name}
                                    </span>
                                    <div className="mt-2 border-t border-gray-200 pt-1">
                                      {navItem.children
                                        .filter(Boolean)
                                        .map((subItem) => (
                                          <SidebarLink
                                            navItem={subItem}
                                            key={subItem.id}
                                            className="sidebar-item-default mb-1 flex w-full items-center justify-start rounded-md px-3 py-1.5 text-left text-sm text-gray-500 hover:bg-gray-100"
                                          >
                                            {subItem.name}
                                            {subItem.beta && (
                                              <Tooltip
                                                title={
                                                  'You’ve been granted early access to this feature! Please note, it is still in testing and may behave unpredictably at times. Use with caution.'
                                                }
                                              >
                                                <BeakerIcon className="ml-2 h-3 w-3 text-yellow-600" />
                                              </Tooltip>
                                            )}
                                          </SidebarLink>
                                        ))}
                                    </div>
                                  </div>
                                )}
                              </Popper>
                            )}
                          </div>
                        </div>
                      ),
                    )}
                  </div>
                </nav>
                <div className="sidebar-bottom w-full">
                  {lowerNavigation.map((navItem) =>
                    !navItem.children ? (
                      <div key={navItem.id} className="w-full">
                        {/* Top level with no children */}
                        <SidebarLink
                          navItem={navItem}
                          className="sidebar-item-default"
                        >
                          {navItem.customIcon ? (
                            navItem.customIcon
                          ) : (
                            <navItem.icon
                              className="sidebar-item-icon"
                              aria-hidden="true"
                            />
                          )}
                          <div className="sidebar-expanded-link !bg-transparent">
                            {navItem.name}
                          </div>
                        </SidebarLink>
                      </div>
                    ) : (
                      <div
                        className="relative min-h-[38px] w-full"
                        key={navItem.name}
                      >
                        <div
                          className="w-full"
                          onMouseEnter={(e) => handlePopoverOpen(e, navItem)}
                          onMouseLeave={() => handlePopoverClose(navItem)}
                        >
                          <SidebarLink
                            navItem={navItem}
                            className={classNames(
                              'sidebar-item-default relative',
                              openSubMenu(navItem.path)
                                ? 'sidebar-item-current'
                                : '',
                            )}
                          >
                            {navItem.customIcon ? (
                              navItem.customIcon
                            ) : (
                              <navItem.icon
                                className="sidebar-item-icon"
                                aria-hidden="true"
                              />
                            )}
                            <div className="sidebar-expanded-link">
                              {navItem.name}
                            </div>
                            <svg
                              className={classNames(
                                toggle ? '-right-0.5' : 'right-2',
                                'absolute top-1 h-3 w-3 flex-shrink-0',
                              )}
                              viewBox="0 0 20 20"
                              aria-hidden="true"
                            >
                              <path
                                d="M6 6L14 10L6 14V6Z"
                                fill="currentColor"
                              />
                            </svg>
                          </SidebarLink>

                          {popoverOpen[navItem.name] && (
                            <Popper
                              className="sidebar-popper"
                              open={popoverOpen[navItem.name]}
                              anchorEl={popoverAnchorEl}
                              placement="right-start"
                              modifiers={[
                                {
                                  name: 'offset',
                                  options: {
                                    offset: [0, 0],
                                  },
                                },
                              ]}
                            >
                              {({ TransitionProps }) => (
                                <div
                                  {...TransitionProps}
                                  className="z-50 w-[250px] rounded-md bg-white p-3 shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none"
                                >
                                  {/* Popover content */}
                                  <span className="py-3 text-xs font-bold uppercase text-gray-500">
                                    {navItem.name}
                                  </span>
                                  <div className="mt-2 border-t border-gray-200 pt-1">
                                    {navItem.children
                                      .filter(Boolean)
                                      .map((subItem) => (
                                        <SidebarLink
                                          navItem={subItem}
                                          key={subItem.name}
                                          className="sidebar-item-default mb-1 flex w-full items-center justify-start rounded-md px-3 py-1.5 text-left text-sm text-gray-500 hover:bg-gray-100"
                                        >
                                          {subItem.name}
                                          {subItem.beta && (
                                            <Tooltip
                                              title={
                                                'You’ve been granted early access to this feature! Please note, it is still in testing and may behave unpredictably at times. Use with caution.'
                                              }
                                            >
                                              <BeakerIcon className="ml-2 h-3 w-3 text-yellow-600" />
                                            </Tooltip>
                                          )}
                                        </SidebarLink>
                                      ))}
                                  </div>
                                </div>
                              )}
                            </Popper>
                          )}
                        </div>
                      </div>
                    ),
                  )}
                  <div
                    key="navSupport"
                    className="flex items-center justify-center"
                  >
                    <Button
                      id="sidebar-help-and-support-btn"
                      variant="text"
                      onClick={() => {
                        trackEvent('Uncategorized', 'Help & support', {
                          openChat: !openChat,
                        })
                        setOpenChat(!openChat)
                      }}
                      className="sidebar-item-default text-gray-500"
                    >
                      <LifebuoyIcon className="" aria-hidden="true" />
                      <div className="sidebar-expanded-link">
                        Help & Support
                      </div>
                    </Button>
                  </div>

                  <StatuspageLinkCell />

                  <div className="flex justify-center pt-4">
                    <div
                      onMouseEnter={(e) => handlePopoverOpen(e, 'hubsInfo')}
                      onMouseLeave={() => handlePopoverClose('hubsInfo')}
                      className="sidebar-item-default flex flex-col"
                    >
                      {toggle ? (
                        <HubLogomark className="h-8 w-8" />
                      ) : (
                        <TheHubLogo className="h-8 w-auto" />
                      )}

                      <div className="mt-2 text-center text-xs font-light leading-[11px] text-gray-400">
                        Privacy Policy
                      </div>

                      {popoverOpen['hubsInfo'] && (
                        <Popper
                          className="sidebar-popper"
                          id="hubsInfo" // Added quotes around the ID value
                          open={popoverOpen['hubsInfo']}
                          anchorEl={popoverAnchorEl}
                          placement="right-start"
                          modifiers={[
                            {
                              name: 'offset',
                              options: {
                                offset: [0, 0],
                              },
                            },
                          ]}
                        >
                          <div className="z-50 w-[250px] rounded-md bg-white p-4 shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <div className="mt-2 flex flex-col items-start justify-center text-xs text-gray-400">
                              <TheHubLogo className="mb-3" />
                              <a
                                href="https://stafflink.com.au/privacy-policy/"
                                className="hover:underline"
                                target="_blank"
                                rel="noreferrer"
                              >
                                Privacy Policy
                              </a>
                              <Link
                                to={routes.acceptableUsePolicy()}
                                className="hover:underline"
                              >
                                Acceptable Use Policy
                              </Link>
                              <Link
                                to={routes.endUserTermsAndConditions()}
                                className="hover:underline"
                              >
                                End User Terms & Conditions
                              </Link>
                            </div>
                          </div>
                        </Popper>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Main column */}
          <div
            className={classNames(
              toggle ? '' : 'sidebar-content-open',
              'sidebar-content flex w-full flex-col',
            )}
          >
            {/* Search header */}
            <div className="absolute top-0 z-10 flex h-[63px] flex-shrink-0 bg-white lg:hidden">
              <Button
                type="button"
                variant="text"
                className="rounded-none"
                onClick={() => setSidebarOpen(true)}
                buttonDataTestId="mobile-sidebar-open-button"
              >
                <Bars3CenterLeftIcon
                  className="h-6 w-6 text-gray-500"
                  aria-hidden="true"
                />
              </Button>
            </div>
            <main className="flex-1 bg-gray-50">{children}</main>
          </div>
        </div>

        {isAliasSwitcherOpen && (
          <AliasSwitcherCell
            closeAliasSwitcher={closeAliasSwitcher}
            selectAlias={selectAlias}
          />
        )}
      </div>
    </>
  )
}

export default Sidebar
