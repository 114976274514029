import { createContext, useMemo, useState } from 'react'

import { getUniqId, loadDayjsExtensions } from 'api/src/common/utils'

import Routes from 'src/Routes'

import { HubsAuthType, initialAuth, resetAuth } from './auth'
import { AuthClient } from './lib/authClients'
import { useIntercomLoad } from './lib/hooks/UseIntercomLoad'
import { useSentry } from './lib/hooks/UseSentry'
import Providers from './Providers'

import './styles/ejs2-icons.css'
import './styles/ejs2-rte-content.css'
import './styles/ejs2-tailwind.css'
import './styles/index.css'
import './styles/react-resizable.css'
import './styles/scaffold.css'
import 'highlight.js/styles/github.css'

const webCommitHash = process.env.GIT_COMMIT_HASH
const webCommitTime = process.env.GIT_COMMIT_TIME
const deploymentSlot = process.env.DEPLOYMENT_SLOT
const browserTabId = getUniqId()

loadDayjsExtensions()

export const AuthClientContext =
  createContext<[HubsAuthType, (client: AuthClient) => void]>(null)

const App = () => {
  useIntercomLoad()
  useSentry({ browserTabId })

  const initAuth = useMemo(() => initialAuth, [])
  const [auth, _setAuth] = useState(initAuth)
  const setAuth = (authClient: AuthClient) => _setAuth(resetAuth(authClient))

  return (
    auth && (
      <>
        <div style={{ visibility: 'hidden', display: 'none' }}>
          <p>CommitId:{webCommitHash}</p>
          <p>CommitTime: {webCommitTime}</p>
          <p>DeploymentSlot: {deploymentSlot}</p>
        </div>
        <AuthClientContext.Provider value={[auth, setAuth]}>
          <Providers browserTabId={browserTabId}>
            <Routes />
          </Providers>
        </AuthClientContext.Provider>
      </>
    )
  )
}

export default App
